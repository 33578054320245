import styled from "styled-components";
import { CSSTransition } from "react-transition-group";

import { Descr } from "components/pages/course-page/offer/style";
import { WrapperWithBorder } from "components/style";
import CourseTags from "components/pages/course-page/course-tags";

import AiryLayoutIcon from "assets/svgs/pages/index/additional-education/secondary/airy-layout.svg";
import CollageIcon from "assets/svgs/pages/index/additional-education/secondary/collage.svg";
import ConstructivismIcon from "assets/svgs/pages/index/additional-education/secondary/constructivism.svg";
import GothicFuturismIcon from "assets/svgs/pages/index/additional-education/secondary/gothic-futurism.svg";
import SwisIcons from "assets/svgs/pages/index/additional-education/secondary/graphic-system-swiss.svg";
import NeoGeoIcon from "assets/svgs/pages/index/additional-education/secondary/neo-geo.svg";
import TechnoIcon from "assets/svgs/pages/index/additional-education/secondary/techno.svg";
import CyberpunkIcon from "assets/svgs/pages/index/additional-education/secondary/cyberpunk.svg";
import BrutalismIcon from "assets/svgs/pages/index/additional-education/secondary/brutalism.svg";

import FoundationFullLogo from "assets/svgs/pages/index/main-education/foundation-full-logo.svg";

import StudyButtonComponent from "./StudyButtonComponent/StudyButtonComponent";

export const MainIllustrationWrapper = styled.div`
  padding: 0.5vw;
  background: white;
  border-radius: 1.5vw;
  box-shadow: 0 0.5vw 1vw rgba(0, 0, 0, 0.15);
  transition: transform ease 0.2s;
  position: relative;
  z-index: 2;

  :hover {
    cursor: pointer;
    transform: scale(1.01);
  }
`;

export const StyledWrapperWithBorder = styled(WrapperWithBorder)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2.3vw 0 1.7vw;
  background: white;

  @media only screen and (max-width: 575px) {
    padding: 5.3vw 0 4.7vw;
  }
`;

export const StyledFoundationFullLogo = styled(FoundationFullLogo)`
  width: 29.5vw;
  margin-bottom: 1.7vw;

  @media only screen and (max-width: 575px) {
    width: 68.5vw;
    margin-bottom: 5vw;
  }
`;

export const StyledAiryLayoutIcon = styled(AiryLayoutIcon)`
  width: 7vw;
  top: -0.4vw;

  @media only screen and (max-width: 575px) {
    width: 27vw;
    top: -3.1vw;
    left: 46vw;
  }
`;
export const StyledCollageIcon = styled(CollageIcon)`
  width: 7vw;
  margin-right: 0.8vw !important;
  margin-left: 0.8vw !important;

  @media only screen and (max-width: 575px) {
    width: 28vw;
    left: 9vw;
    top: 32vw;
  }
`;
export const StyledConstructivismIcon = styled(ConstructivismIcon)`
  width: 8vw;
  margin-right: 1.1vw !important;
  left: 0.1vw;
  top: -0.3vw;

  @media only screen and (max-width: 575px) {
    width: 31vw;
    left: 44.3vw;
    top: 49.3vw;
  }
`;
export const StyledGothicFuturismIcon = styled(GothicFuturismIcon)`
  width: 6vw;
  top: -0.2vw;
  left: -0.3vw;

  @media only screen and (max-width: 575px) {
    width: 24vw;
    left: 29.7vw;
    top: 62.8vw;
  }
`;
export const StyledSwisIcons = styled(SwisIcons)`
  width: 6.6vw;
  top: -0.2vw;

  @media only screen and (max-width: 575px) {
    width: 24.6vw;
    top: 0;
    left: 10vw;
  }
`;
export const StyledNeoGeoIcon = styled(NeoGeoIcon)`
  width: 4.2vw;
  top: -0.2vw;
  left: 0.1vw;

  @media only screen and (max-width: 575px) {
    width: 16.2vw;
    top: 13.8vw;
    left: 51.1vw;
  }
`;
export const StyledTechnoIcon = styled(TechnoIcon)`
  width: 5.5vw;
  top: -0.2vw;
  left: 0.7vw;

  @media only screen and (max-width: 575px) {
    width: 23.5vw;
    left: 48.7vw;
    top: 29.8vw;
  }
`;
export const StyledCyberpunkIcon = styled(CyberpunkIcon)`
  width: 6vw;
  margin-right: 1.1vw !important;
  top: -0.3vw;
  left: 0.2vw;

  @media only screen and (max-width: 575px) {
    width: 22vw;
    top: 48.7vw;
    left: 11.2vw;
  }
`;

export const StyledBrutalismIcon = styled(BrutalismIcon)`
  width: 9.5vw;
  top: -0.1vw;

  @media only screen and (max-width: 575px) {
    width: 37.5vw;
    left: 5vw;
    top: 15vw;
  }
`;

export const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media only screen and (max-width: 575px) {
    flex-direction: column-reverse;
  }
`;

export const CoursesIconsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 0.6vw;
  width: 50%;

  svg {
    margin: 0 0.5vw 0.5vw;
    position: relative;
  }

  @media only screen and (max-width: 575px) {
    position: relative;
    height: 80vw;
    width: 100%;
    margin-top: 5vw;

    svg {
      position: absolute;
    }
  }
`;

export const StyledFoundationBasicIconsDesktop = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2vw;

  svg {
    width: 5vw;
    margin-right: 1.55vw;

    :last-child {
      margin-right: 0;
    }
  }

  @media only screen and (max-width: 575px) {
    display: none;
  }
`;

export const StyledFoundationBasicIconsMobile = styled.div`
  display: none;

  @media only screen and (max-width: 575px) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 81%;
    svg {
      width: 15vw;
      // margin-right: 3vw;
      margin-bottom: 3vw;
    }
  }
`;

export const Tags = styled.div`
  display: flex;
  justify-content: center;

  @media only screen and (max-width: 575px) {
    padding: 0 3.5vw;
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  a {
    margin: 0;
  }
`;

export const StyledDescr = styled(Descr)`
  text-align: center;
  width: 37vw;
  margin-top: 0.8vw;
  font-weight: normal;
  line-height: 1.2;
  font-size: 1.5vw;
  margin-bottom: 1.6vw;

  @media only screen and (max-width: 575px) {
    width: 94%;
    font-size: 4.4vw;
    margin-top: 0;
    margin-bottom: 5.6vw;
  }
`;

export const StyledCourseTags = styled(CourseTags)`


  @media only screen and (max-width: 575px) {
    justify-content: center;
    width: 80%;
    margin-bottom: 3vw;

    div {
      font-size: 4.1vw;
      border-width: 1vw;
      border-radius: 10vw;
      padding: 0.7vw 2vw;
      margin-bottom: 2.3vw;
      font-weight: 600;
      letter-spacing: -0.1vw;
    }

    .courses-order-course-status-tag {
      margin-right: 10vw;
      margin-left: 10vw;
    }
  }
`;

export const StyledStudyButtonComponent = styled(StudyButtonComponent)`
  @media only screen and (max-width: 575px) {
    padding: 4vw 7vw !important;
  }
`;

export const StyledCSSTransition = styled(CSSTransition)`
  position: fixed;
  width: 17vw;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
  bottom: 1.5vw;

  &.study-button-transition-enter {
    bottom: -6vw;
  }

  &.study-button-transition-enter-active {
    bottom: 1.5vw;
    transition: bottom 300ms;
  }

  &.study-button-transition-exit {
    bottom: 1.5vw;
  }

  &.study-button-transition-exit-active {
    bottom: -6vw;
    transition: bottom 300ms;
  }

    @media only screen and (max-width: 575px) {
    width: 40vw;
      bottom: 22vw;
      z-index: 998;
      &.study-button-transition-enter {
        bottom: -16.5vw;
      }

      &.study-button-transition-enter-active {
        bottom: 22vw;
      }

      &.study-button-transition-exit {
        bottom: 22vw;
      }

      &.study-button-transition-exit-active {
        bottom: -16.5vw;
      }
    }
`;
