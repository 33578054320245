import React, { useRef, useState } from "react";
import { Formik } from "formik";
import Cross from "assets/svgs/cross.svg";

import ShakeForm from "components/common/shake-form";
import useGetCourseFormEncrypt from "hooks/useGetCourseFormEncrypt";
import useAdaptive from "hooks/useAdaptive";

import getValidationSchema from "./validation-schema";
import * as SC from "./style";

//
const PricePackagesModal = ({
  pricePackages = [],
  chosenPackage = {},
  closeModal = () => {},
  courseData = {},
}) => {
  const [modalChosenPackage, setModalChosenPackage] = useState(chosenPackage);
  const formEl = useRef(null);
  const submitForm = () => {
    formEl.current.submit();
  };

  const [shakeTrigger, setShakeTrigger] = useState(3);
  function isInitialValid(props) {
    if (!props.validationSchema) return true;
    return props.validationSchema.isValidSync(props.initialValues);
  }

  const { isMobile } = useAdaptive();

  const { getCourseTags, getCourseButtonOnClick } = useGetCourseFormEncrypt();

  const {
    packageName: chosenPackageName,
    formAction,
    formId,
  } = modalChosenPackage;

  return (
    <>
      <ShakeForm pose={["shake"]} poseKey={shakeTrigger}>
        <Formik
          isInitialValid={isInitialValid}
          initialValues={{
            formParams: {
              email: "",
              first_name: "",
              last_name: "",
              phone: "",
            },
          }}
          onSubmit={submitForm}
          validationSchema={getValidationSchema()}
        >
          {props => {
            const {
              values,
              errors,
              isSubmitting,
              handleChange,
              handleSubmit,
              isValid,
              touched,
            } = props;
            return (
              <SC.StyledWrapperWithBorder>
                <SC.Form
                  ref={formEl}
                  onSubmit={e => {
                    if (isValid) {
                      handleSubmit(e);
                    } else {
                      handleSubmit(e);
                      setShakeTrigger(shakeTrigger + 1);
                      if (errors.formParams && errors.formParams.email) {
                        values.formParams.email = "";
                      }
                      if (errors.formParams && errors.formParams.phone) {
                        values.formParams.phone = "";
                      }
                    }
                  }}
                  id={formId}
                  action={formAction}
                  method="post"
                  data-open-new-window="0"
                >
                  <SC.FormWrapper>
                    <SC.FormCross onClick={closeModal}>
                      <Cross />
                    </SC.FormCross>

                    <SC.FormContainer>
                      <SC.FormTitle>Выберите пакет:</SC.FormTitle>
                      <input type="hidden" name="formParams[setted_offer_id]" />
                      <input
                        id="input-formPosition9651352"
                        name="formParams[need_offer]"
                        type="hidden"
                        value=""
                      />
                      <br />
                      <SC.PackagesWrapper>
                        {pricePackages.map((pckg, idx) => {
                          const { packageName, prismicType } = pckg;
                          const isCourseClosed =
                            prismicType === "group" &&
                            !courseData?.coursestatus;

                          return (
                            <SC.Package
                              $isChosen={packageName === chosenPackageName}
                              $isDisabled={isCourseClosed}
                              key={idx}
                              onClick={
                                isCourseClosed
                                  ? () => {}
                                  : () => setModalChosenPackage(pckg)
                              }
                            >
                              <span />
                              Пакет {packageName}
                            </SC.Package>
                          );
                        })}
                      </SC.PackagesWrapper>
                      <SC.PackagesDescr>
                        В случае покупки пакета Соло вы всегда
                        можете доплатить недостающую сумму и взять пакет «Поток».
                        Для этого напишите нам на{" "}
                        <SC.StyledCopyMailToClipboardComponent>
                          <SC.Email>hello@granich.design</SC.Email>
                        </SC.StyledCopyMailToClipboardComponent>
                      </SC.PackagesDescr>
                      <br />
                      <SC.Input
                        id="formParams[first_name]"
                        type="text"
                        placeholder={
                          errors.formParams &&
                          errors.formParams.first_name &&
                          touched.formParams &&
                          touched.formParams.first_name
                            ? errors.formParams.first_name
                            : "Имя (по паспорту)"
                        }
                        name="formParams[first_name]"
                        value={values.formParams.first_name}
                        onChange={handleChange}
                        errorStyle={
                          errors.formParams &&
                          errors.formParams.first_name &&
                          touched.formParams &&
                          touched.formParams.first_name
                            ? true
                            : false
                        }
                      />
                      <SC.Input
                        id="formParams[last_name]"
                        type="text"
                        placeholder={
                          errors.formParams &&
                          errors.formParams.last_name &&
                          touched.formParams &&
                          touched.formParams.last_name
                            ? errors.formParams.last_name
                            : "Фамилия (по паспорту)"
                        }
                        name="formParams[last_name]"
                        value={values.formParams.last_name}
                        onChange={handleChange}
                        errorStyle={
                          errors.formParams &&
                          errors.formParams.last_name && 
                          touched.formParams &&
                          touched.formParams.last_name
                            ? true
                            : false
                        }
                      />
                      <SC.Input
                        id="formParams[email]"
                        type="text"
                        maxLength={60}
                        placeholder={
                          errors.formParams &&
                          errors.formParams.email &&
                          touched.formParams &&
                          touched.formParams.email
                            ? errors.formParams.email
                            : "Электропочта (для входа)"
                        }
                        name="formParams[email]"
                        value={values.formParams.email}
                        onChange={handleChange}
                        errorStyle={
                          errors.formParams &&
                          errors.formParams.email &&
                          touched.formParams &&
                          touched.formParams.email
                            ? true
                            : false
                        }
                      />
                      <SC.Input
                        id="formParams[phone]"
                        type="tel"
                        maxLength={60}
                        placeholder={
                          errors.formParams &&
                          errors.formParams.phone &&
                          touched.formParams &&
                          touched.formParams.phone
                            ? errors.formParams.phone
                            : `Телефон (только для ${isMobile ? "ЧП" : "экстренной связи"})`
                        }
                        name="formParams[phone]"
                        value={values.formParams.phone}
                        onChange={handleChange}
                        errorStyle={
                          errors.formParams &&
                          errors.formParams.phone &&
                          touched.formParams &&
                          touched.formParams.phone
                            ? true
                            : false
                        }
                      />
                    </SC.FormContainer>
                  </SC.FormWrapper>
                  <SC.FormFooterWrapper>
                    <SC.StyledPayButtonComponent
                      disabled={isSubmitting}
                      type="submit"
                      id="button1106661"
                      onClick={getCourseButtonOnClick}
                      icon={
                        <SC.StyledFoundationLogoWrapper>
                          <SC.StyledFoundationLogo />
                        </SC.StyledFoundationLogoWrapper>
                      }
                      target="_blank"
                      theme="red"
                    >
                      Оплатить
                    </SC.StyledPayButtonComponent>
                    <SC.FormPolitikaLabel>
                      Нажимая на кнопку в этой форме, я принимаю условия{" "}
                      <a target="_blank" href="/privacy">
                        {" "}
                        политики конфиденциальности
                      </a>{" "}
                      и{" "}
                      <a target="_blank" href="/public-offer">
                        {" "}
                        учебного договора
                      </a>
                    </SC.FormPolitikaLabel>
                  </SC.FormFooterWrapper>
                  {getCourseTags}
                </SC.Form>
              </SC.StyledWrapperWithBorder>
            );
          }}
        </Formik>
      </ShakeForm>
    </>
  );
};

export default PricePackagesModal;
