import React, { forwardRef } from "react";
import scrollTo from "gatsby-plugin-smoothscroll";

import * as SC from "./style";

const StudyButtonComponent = forwardRef(({ className }, ref) => (
  <SC.StyledButtonComponent
    ref={ref}
    icon={
      <SC.FoundationLogoWrapper>
        <SC.StyledFoundationButtonLogo />
      </SC.FoundationLogoWrapper>
    }
    target="_blank"
    theme="red"
    className={className}
    // asProp="a"
    // to="#participation-section"
    onClick={() => scrollTo("#participation-section")}
  >
    Учиться
  </SC.StyledButtonComponent>
));

export default StudyButtonComponent;
