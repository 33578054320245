import React from "react";
import { useInView } from "react-intersection-observer";
import { Container } from "components/style";
import { Section } from "components/pages/course-page/offer/style";

import BasicIconOne from "assets/svgs/foundation-of-graphic-design/basics/first.svg";
import BasicIconTwo from "assets/svgs/foundation-of-graphic-design/basics/second.svg";
import BasicIconThree from "assets/svgs/foundation-of-graphic-design/basics/third.svg";
import BasicIconFour from "assets/svgs/foundation-of-graphic-design/basics/four.svg";
import BasicIconFive from "assets/svgs/foundation-of-graphic-design/basics/five.svg";
import BasicIconSix from "assets/svgs/foundation-of-graphic-design/basics/six.svg";
import BasicIconSeven from "assets/svgs/foundation-of-graphic-design/basics/seven.svg";
import BasicIconEight from "assets/svgs/foundation-of-graphic-design/basics/eight.svg";
import BasicIconNine from "assets/svgs/foundation-of-graphic-design/basics/nine.svg";
import BasicIconTen from "assets/svgs/foundation-of-graphic-design/basics/ten.svg";

import * as SC from "./style";

const FoundationOffer = ({ data, courseDescr, isFormInView }) => {
  const { ref, inView } = useInView({ threshold: 0 });
  return (
    <Section>
      <Container>
        <SC.StyledWrapperWithBorder ref={ref}>
          <SC.StyledFoundationFullLogo />
          <SC.StyledFoundationBasicIconsDesktop>
            <BasicIconEight/>
            <BasicIconSeven/>
            <BasicIconSix/>
            <BasicIconFour/>
            <BasicIconTwo/>
            <BasicIconThree/>
            <BasicIconOne/>
            <BasicIconFive/>
            <BasicIconNine/>
            <BasicIconTen/>
          </SC.StyledFoundationBasicIconsDesktop>
          <SC.StyledFoundationBasicIconsMobile>
            <BasicIconFour/>
            <BasicIconThree/>
            <BasicIconEight/>
            <BasicIconSeven/>
            <BasicIconSix/>
            <BasicIconNine/>
            <BasicIconFive/>
            <BasicIconTwo/>
          </SC.StyledFoundationBasicIconsMobile>
          <SC.CoursesIconsWrapper>
            <SC.StyledSwisIcons />
            <SC.StyledAiryLayoutIcon />
            <SC.StyledCollageIcon />
            <SC.StyledNeoGeoIcon />
            <SC.StyledTechnoIcon />
            <SC.StyledCyberpunkIcon />
            <SC.StyledConstructivismIcon />
            <SC.StyledGothicFuturismIcon />
            <SC.StyledBrutalismIcon />
          </SC.CoursesIconsWrapper>
          <SC.Info>
            <SC.FlexWrapper>
              <SC.Tags>
                <SC.StyledCourseTags withCourseType={false} course={data} />
              </SC.Tags>
              <SC.StyledDescr
                courseTitle={data.coursetitle?.text}
                type={data.coursetype}
              >
                {courseDescr ?? data.coursedescription?.text}
              </SC.StyledDescr>
            </SC.FlexWrapper>
            <SC.StyledStudyButtonComponent />
          </SC.Info>
        </SC.StyledWrapperWithBorder>
      </Container>
      <SC.StyledCSSTransition
        in={!inView && !isFormInView}
        timeout={300}
        classNames="study-button-transition"
        unmountOnExit
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          <SC.StyledStudyButtonComponent />
        </div>
      </SC.StyledCSSTransition>
    </Section>
  );
};

export default FoundationOffer;
