import styled from "styled-components";
import FoundationButtonLogo from "assets/svgs/courses-order/foundation-of-graphic-design-v2-logo.svg";
import ButtonComponent from "components/layout/button";

export const FoundationLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  border-radius: 50%;
  border: 0.15vw solid var(--granich-black);
  padding: 0.2vw;
  width: 3.4vw;
  min-width: 3.4vw;
  height: 3.4vw;

  @media only screen and (max-width: 575px) {
    width: 13vw;
    min-width: 13vw;
    height: 13vw;
    border-width: 0.8vw;
    padding: 1vw;
    margin-right: 2vw;

    svg {
      width: 4.5vw;
      height: 4.5vw;
      position: relative;
      right: -1vw;
    }
  }
`;

export const StyledFoundationButtonLogo = styled(FoundationButtonLogo)``;

export const StyledButtonComponent = styled(ButtonComponent)`
  padding: 1.1vw 1.9vw;
  box-shadow: 0 0.5vw 2vw rgba(255, 209, 83, 0.5);
  margin-right: 2.9vw;
  span {
    font-size: 2.1vw;
    font-weight: 600;
  }

  svg {
    width: 100%;
    transform: scale(2) translateX(1%);
  }

  @media only screen and (max-width: 575px) {
    box-shadow: none;
    padding: 3.5vw 4vw;
    border-radius: 15vw;
    position: relative;
    span {
      font-size: 4.5vw;
    }

    div {
      width: 9vw;
      min-width: 9vw;
      height: 9vw;
    }

    svg {
      width: 100%;
      transform: scale(2) translateX(1%);
      width: 3vw;
      min-width: 3vw;
      height: 3vw;
    }
  }
`;
