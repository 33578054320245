// react/gatsby imports
import React from "react";
import { useInView } from "react-intersection-observer";
// assets
import ogImage from "assets/images/seo/foundation-of-graphic-design.jpg";
import ogImageVK from "assets/images/seo/vk/foundation-of-graphic-design.jpg";
// data
import localData from "data/pages/foundation-of-graphic-design";
// hooks
import useFoundationPortfolio from "hooks/portfolio/useFoundationPortfolio";
import useFoundationTestQuery from "hooks/graphql/useFoundationTestQuery";
// utilities
import generateCanonicalLink from "utilities/link-utils";
// constants
import PAGE_SLUGS from "constants/page-slugs";
// components
import Layout from "components/layout";
import SEO from "components/layout/seo";
import CourseTimetable from "components/pages/course-page/course-timetable";
import VideoPresentation from "components/pages/course-page/video-presentation";
import CourseSystem from "components/pages/foundation-of-graphic-design/course-system";
import FoundationForWhom from "components/pages/foundation-of-graphic-design/for-whom";
import FoundationPortfolio from "components/pages/foundation-of-graphic-design/foundation-portfolio";
import CoursePricePackages from "components/pages/course-page/price-packages";
import FoundationOffer from "components/pages/foundation-of-graphic-design/offer";
import SoftwareHomeworkSection from "components/pages/foundation-of-graphic-design/software-homework";
import FoundationLessonsOrder from "components/pages/foundation-of-graphic-design/lessons-order";
import FoundationMoreBonusesSection from "components/pages/foundation-of-graphic-design/bonuses";
import FoundationALotOfFeedbackSection from "components/pages/foundation-of-graphic-design/a-lot-of-feedback";
import GranichPaymentChoicesNew from "components/pages/course-page/payment-choices";
import PageTransitionGradient from "components/pages/index/page-transition-gradient/style";
import CourseQandNew from "components/pages/course-page/q-and-a_new";

const FoundationOfGraphicDesignPage = () => {
  const prismicData = useFoundationTestQuery();
  const portfolio = useFoundationPortfolio();
  const { data } = prismicData.course;
  const { data: seoData } = prismicData.page || {};

  const { ref, inView } = useInView({ threshold: 0 });
  console.log("inView", inView);

  return (
    <Layout isWhite>
      <SEO
        title="Фундамент Графдизайна 2.5"
        description={
          seoData?.seodescription?.text ||
          "Курс с дедлайнами для уверенного старта в графическом дизайне. Основан на дизайн-системе и композициях."
        }
        keywords={[
          "графдизайн",
          "фундамент",
          "foundation",
          "гранич",
          "graphdesign",
          "granich",
        ]}
        ogImage={seoData?.seoimage?.url || ogImage}
        ogImageVk={seoData?.seoimage?.thumbnails?.vk?.url || ogImageVK}
        url={generateCanonicalLink(PAGE_SLUGS.foundationOfGraphicDesign)}
        noIndex
      />
      <div className="section-top-block" />
      <FoundationOffer
        data={data}
        isFormInView={inView}
        courseDescr="Интенсивный курс графического дизайна. Обучение композициям. Графическим системам. И продвинутым инструментам графдизайна." 
      />
      <FoundationForWhom />
      <FoundationPortfolio
        courseTitle={data.coursetitle?.text}
        portfolio={portfolio}
        isShowMoreParam
      />
      <VideoPresentation />
      <CourseSystem />
      <CourseTimetable
        courseTitle={data.coursetitle?.text}
        text={
          <>
            Программа обучения выстроена так, что на каждый урок с ДЗ даётся
            неделя — со среды по вторник. За этот срок нужно изучить материалы
            урока и отправить выполненное по ним ДЗ — иначе вы не получите
            разбор куратора по этому домашнему заданию (в том числе не сможете
            делать творческий макет из этого ДЗ). Уроки насыщены обязательной
            к изучению и дополнительной информацией, так что лучше не затягивать
            и распределять нагрузку равномерно в течение недели{" "}
            <i>(хотя бы два часа каждый день).</i>
          </>
        }
      />
      <FoundationLessonsOrder />
      <SoftwareHomeworkSection />
      <FoundationALotOfFeedbackSection />
      <FoundationMoreBonusesSection />
      <div id="participation-section" />
      <PageTransitionGradient $isReversed />
      <CoursePricePackages
        pricePackages={localData.pricePackages}
        googleAnalyticsCategory="Отправка формы Фундамент Графдизайна"
        courseData={data}
        ref={ref}
      />
      <GranichPaymentChoicesNew />
      <CourseQandNew />
    </Layout>
  );
};

export default FoundationOfGraphicDesignPage;
